.bb7hpj8ar {
	background-color: rgb(255, 255, 255);
	box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
	padding: 1.5rem;
	border-radius: 10px;
	border: 1px solid rgba(211, 211, 211, 0.397);
	max-width: 810px;
	margin: 60px auto;
	display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.xzypfayps {
	font-weight: bold;
    font-size: 23px;
    text-align: center;
}

.hazc5cnjq {
	font-style: italic;
    text-align: center;
    margin-top: 6px;
    color: #717171;
    font-size: 17px;
}

.jptxhwzrc {
	margin: 0 auto;
    height: 2px;
    width: 20%;
    background: orange;
    margin-top: 18px;
}

.kbp2xdvkn {
	text-align: center;
    font-style: italic;
    margin-top: 19px;
}

.sjzysgemu {
	text-align: center;
	margin-top: 30px;
}

.wbsemh5cu {
	margin-bottom: 20px;
}

@media (max-width: 1380px) {
	.bb7hpj8ar {
	    max-width: 690px;
	    margin-bottom: 0;
	}
}

@media (max-width: 1200px) {
	.bb7hpj8ar {
		margin-top: 50px;
	    max-width: 660px;
	}

	.sjzysgemu {
		margin-top: 10px;
	}

	.kbp2xdvkn {
		margin-top: 40px;
	}

	.jptxhwzrc {
		width: 40%;
	}
}